<template>
  <div class="content">
    <div class="top">
      <div class="logo">
        <img src="~static/logo.png">
      </div>

      <div class="headline">
        <h1>{{ title_cn }}</h1>
        <h2>{{ title_en }}</h2>
      </div>
    </div>

    <p class="poem">{{ poem }}</p>

    <div class="center">
      <div class="classify_logo">
        <img :src="classify[0].img">
        <p>{{ classify[0].title }}</p>
      </div>

      <span class="splice" />

      <router-link class="classify_logo" to="/fruits" tag="div">
        <img :src="classify[1].img">
        <p>{{ classify[1].title }}</p>
      </router-link>
    </div>
  </div>
</template>
<script>
import snack_logo from '@/assets/snack_logo.svg'
import fruit_logo from '@/assets/fruit_logo.svg'

export default {
  name: 'DsHome',
  data() {
    return {
      classify: [
        {
          title: '小吃',
          img: snack_logo
        },
        {
          title: '水果',
          img: fruit_logo
        }
      ],
      title_cn: '吃货指南',
      title_en: 'Food-Geo Mapping',
      poem: '所爱隔山海，山海不可平。若为美食往，千里皆可行'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/mixin.scss';

// $bgColor: rgba(51, 51, 51, 0.1);
$fontColor1: rgb(56, 186, 238);
$fontColor2: rgb(255, 240, 1);
$poemColor: #cccccc;

.content {
  padding-top: 100px;
  // background: $bgColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  > .top {
    display: flex;
    > .logo {
      width: 160px;
      height: 160px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    > .headline {
      width: 550px;
      > h1 {
        font-size: 60px;
        line-height: 60px;
        margin: 10px 0;
        color: $fontColor1;
      }
      > h2 {
        margin: 0;
        line-height: 50px;
        font-size: 50px;
        margin: 20px 0;
        color: $fontColor2;
      }
    }
  }
  > .poem {
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    color: $poemColor;
  }
  > .center {
    margin-top: 20px;
    height: 350px;
    display: flex;
    align-items: center;

    > .splice {
      margin: 0 120px;
      width: 3px;
      height: 100%;
      background-color: #fff;
    }

    > .classify_logo {
      cursor: pointer;
      > img {
        width: 100%;
        height: auto;
      }
      > p {
        font-size: 30px;
        margin: 0;
      }
      &:nth-of-type(1) {
        > p {
          color: $fontColor1;
        }
      }
      &:nth-of-type(2) {
        > p {
          color: $fontColor2;
        }
      }
    }
  }
}
</style>
